import {startCase} from 'lodash';
import {DropdownOption} from '../components/Data/DataConst';

export enum PricingItemStatus {
  CREATED = 'created',
  SENT = 'invoiced',
  PAID = 'paid',
  WRITTEN_OFF = 'written-off',
}

export interface ICostItem {
  _id: string;
  created: Date;
  createdBy: String;

  name: string;
  stage: JobStage;
  type: string;
  description: string;
  invoiceNumber: string;
  company: string;
  amount: number;
}

export interface IPricingItem {
  _id: string;
  created: Date;
  createdBy: string;
  status: PricingItemStatus;
  paymentNumber: string;
  description?: string;
  amount: number;
  invoiceNumber?: string;
}

export interface iCustomField {
  _id: string;
  field: string;
  value: string;
}

export interface Job {
  _id: string;
  __v: number;
  created: Date;
  createdBy: string;
  jobType: string;
  vat: boolean;
  jobPrefix?: string;
  jobId: string;
  leadSource?: string;
  company: string;
  customer: string;
  contact: {
    billing: string;
    installation: string;
  };
  type: string;
  // Deprecated in favour of 'pricing'
  price?: {
    value?: number;
    deposit?: number;
    due?: number;
  };
  pricing: IPricingItem[];
  description: string;
  attachments: {
    created: Date;
    createdBy: string;
    mediaId: string;
  }[];
  stage: string;
  stages: {
    pos: Stage;
    pre: Stage;
    admin: Stage;
    post: Stage;
    service: Stage;
  };
  locations: {
    created: Date;
    createdBy: string;
    location: string;
  }[];
  costs: ICostItem[];
  costReport?: string;
  customFields?: iCustomField[];
}

export interface Stage {
  start: null | Date;
  startAllDay: boolean;
  due: null | Date;
  dueAllDay: boolean;
  assignedTo: string[];
  status: JobStatus;
  events: {
    created: Date;
    createdBy: string;
    type: string;
    value: string;
  }[];
  notes: {
    created: Date;
    createdBy: string;
    text: string;
  }[];
  media: {
    created: Date;
    createdBy: string;
    mediaId: string;
  }[];
  checklist: {
    created: Date;
    createdBy: string;
    text: string;
    status: string;
  }[];
  additional: {
    created?: Date;
    createdBy?: string;
    contractChanges?: boolean;
    contractChangeDetails?: string;
    delays?: boolean;
    delayDetails?: string;
  };
  signature: {
    created: Date;
    createdBy: string;
    signatureObtained: boolean;
    signatureDeclinedReason?: string;
    customerPresent: boolean;
    name: string;
    media: {
      created: Date;
      createdBy: string;
      mediaId: string;
    };
    termsAgreed: boolean;
    rating?: number;
    status: JobStatus;
    comments?: string;
  }[];
  report?: string;
}

export enum JobStage {
  POS = 'pos',
  PRE = 'pre',
  ADMIN = 'admin',
  POST = 'post',
  SERVICE = 'service',
}

export enum JobType {
  COMMERCIAL = 'commercial',
  DOMESTIC = 'domestic',
}

export const JobTypeOptions = [
  {
    text: startCase(JobType.DOMESTIC),
    value: JobType.DOMESTIC,
  },
  {
    text: startCase(JobType.COMMERCIAL),
    value: JobType.COMMERCIAL,
  },
];

export const JobVATOptions = [
  {
    text: 'VAT rate applies',
    value: true,
  },
  {
    text: 'No VAT',
    value: false,
  },
];

export const jobStageMap = (stage: JobStage, stageTitles?) => {
  if (stageTitles && stageTitles[stage] && stageTitles[stage].length) {
    return stageTitles[stage];
  }

  switch (stage) {
    case JobStage.POS:
      return 'Sales';
    case JobStage.PRE:
      return 'Survey';
    case JobStage.ADMIN:
      return 'Admin';
    case JobStage.POST:
      return 'Install';
    case JobStage.SERVICE:
      return 'Service';
  }

  return stage;
};

export const jobDefaultStageTitleMap = (stageTitle: string) => {
  switch (stageTitle) {
    case 'sales':
      return JobStage.POS;
    case 'survey':
      return JobStage.PRE;
    case 'admin':
      return JobStage.ADMIN;
    case 'install':
      return JobStage.POST;
    case 'service':
      return JobStage.SERVICE;
  }

  return stageTitle;
};

export enum JobStatus {
  NOT_STARTED = 'not-started',
  IN_PROGRESS = 'in-progress',
  NOT_COMPLETED = 'not-completed',
  COMPLETED = 'completed',
  COMPLETED_PAID = 'completed-paid',
  COMPLETED_UNPAID = 'completed-unpaid',
  CANCELLED = 'cancelled',
  QUOTED = 'quoted',
  ON_HOLD = 'on-hold',
  LOST = 'lost',
  SOLD = 'sold',
}

export const jobInitialState: Job = {
  _id: '',
  __v: 0,
  created: new Date(),
  createdBy: '',
  jobType: '',
  vat: true,
  jobId: '',
  company: '',
  customer: '',
  contact: {
    billing: '',
    installation: '',
  },
  type: '',
  price: {
    value: 0,
    deposit: 0,
    due: 0,
  },
  pricing: [],
  description: '',
  attachments: [],
  stage: '',
  stages: {
    pos: {
      start: null,
      startAllDay: false,
      due: null,
      dueAllDay: false,
      assignedTo: [],
      status: JobStatus.NOT_STARTED,
      events: [],
      notes: [],
      media: [],
      additional: {},
      signature: [],
      checklist: [],
    },
    pre: {
      start: null,
      startAllDay: false,
      due: null,
      dueAllDay: false,
      assignedTo: [],
      status: JobStatus.NOT_STARTED,
      events: [],
      notes: [],
      media: [],
      additional: {},
      signature: [],
      checklist: [],
    },
    admin: {
      start: null,
      startAllDay: false,
      due: null,
      dueAllDay: false,
      assignedTo: [],
      status: JobStatus.NOT_STARTED,
      events: [],
      notes: [],
      media: [],
      additional: {},
      signature: [],
      checklist: [],
    },
    post: {
      start: null,
      startAllDay: false,
      due: null,
      dueAllDay: false,
      assignedTo: [],
      status: JobStatus.NOT_STARTED,
      events: [],
      notes: [],
      media: [],
      additional: {},
      signature: [],
      checklist: [],
    },
    service: {
      start: null,
      startAllDay: false,
      due: null,
      dueAllDay: false,
      assignedTo: [],
      status: JobStatus.NOT_STARTED,
      events: [],
      notes: [],
      media: [],
      additional: {},
      signature: [],
      checklist: [],
    },
  },
  locations: [],
  costs: [],
};

export class JobEnums {
  public static readonly JobStages = [
    JobStage.POS,
    JobStage.PRE,
    JobStage.ADMIN,
    JobStage.POST,
    JobStage.SERVICE,
  ];

  public static readonly JobStageOptions = (stageTitles) => [
    {
      text: jobStageMap(JobStage.POS, stageTitles),
      value: JobStage.POS,
    },
    {
      text: jobStageMap(JobStage.PRE, stageTitles),
      value: JobStage.PRE,
    },
    {
      text: jobStageMap(JobStage.ADMIN, stageTitles),
      value: JobStage.ADMIN,
    },
    {
      text: jobStageMap(JobStage.POST, stageTitles),
      value: JobStage.POST,
    },
    {
      text: jobStageMap(JobStage.SERVICE, stageTitles),
      value: JobStage.SERVICE,
    },
  ];

  public static readonly JobStatuses = [
    JobStatus.NOT_STARTED,
    JobStatus.IN_PROGRESS,
    JobStatus.NOT_COMPLETED,
    JobStatus.COMPLETED,
    JobStatus.COMPLETED_PAID,
    JobStatus.COMPLETED_UNPAID,
    JobStatus.CANCELLED,
    JobStatus.QUOTED,
    JobStatus.ON_HOLD,
    JobStatus.LOST,
    JobStatus.SOLD,
  ];

  public static readonly CompletedJobStatuses = [
    JobStatus.COMPLETED,
    JobStatus.COMPLETED_PAID,
    JobStatus.COMPLETED_UNPAID,
    JobStatus.LOST,
    JobStatus.SOLD,
  ];

  public static readonly JobStatusOptions = [
    {
      text: startCase(JobStatus.NOT_STARTED),
      value: JobStatus.NOT_STARTED,
      label: 'red',
    },
    {
      text: startCase(JobStatus.IN_PROGRESS),
      value: JobStatus.IN_PROGRESS,
      label: 'yellow',
    },
    {
      text: startCase(JobStatus.NOT_COMPLETED),
      value: JobStatus.NOT_COMPLETED,
      label: 'red',
    },
    {
      text: startCase(JobStatus.CANCELLED),
      value: JobStatus.CANCELLED,
      label: 'red',
    },
    {
      text: startCase(JobStatus.COMPLETED),
      value: JobStatus.COMPLETED,
      label: 'green',
    },
    {
      text: startCase(JobStatus.COMPLETED_PAID),
      value: JobStatus.COMPLETED_PAID,
      label: 'green',
    },
    {
      text: startCase(JobStatus.COMPLETED_UNPAID),
      value: JobStatus.COMPLETED_UNPAID,
      label: 'green',
    },
    {
      text: startCase(JobStatus.QUOTED),
      value: JobStatus.QUOTED,
      label: 'orange',
    },
    {
      text: startCase(JobStatus.ON_HOLD),
      value: JobStatus.ON_HOLD,
      label: 'orange',
    },
    {
      text: startCase(JobStatus.LOST),
      value: JobStatus.LOST,
      label: 'orange',
    },
    {
      text: startCase(JobStatus.SOLD),
      value: JobStatus.SOLD,
      label: 'green',
    },
  ];

  public static readonly PosStageOptions = [
    {
      text: startCase(JobStatus.NOT_STARTED),
      value: JobStatus.NOT_STARTED,
      label: 'red',
    },
    {
      text: startCase(JobStatus.IN_PROGRESS),
      value: JobStatus.IN_PROGRESS,
      label: 'yellow',
    },
    {
      text: startCase(JobStatus.QUOTED),
      value: JobStatus.QUOTED,
      label: 'orange',
    },
    {
      text: startCase(JobStatus.ON_HOLD),
      value: JobStatus.ON_HOLD,
      label: 'orange',
    },
    {
      text: startCase(JobStatus.LOST),
      value: JobStatus.LOST,
      label: 'orange',
    },
    {
      text: startCase(JobStatus.SOLD),
      value: JobStatus.SOLD,
      label: 'green',
    },
  ];

  public static readonly PreStageOptions = [
    {
      text: startCase(JobStatus.NOT_STARTED),
      value: JobStatus.NOT_STARTED,
      label: 'red',
    },
    {
      text: startCase(JobStatus.IN_PROGRESS),
      value: JobStatus.IN_PROGRESS,
      label: 'yellow',
    },
    {
      text: startCase(JobStatus.NOT_COMPLETED),
      value: JobStatus.NOT_COMPLETED,
      label: 'orange',
    },
    {
      text: startCase(JobStatus.COMPLETED),
      value: JobStatus.COMPLETED,
      label: 'green',
    },
  ];

  public static readonly AdminStageOptions = [
    {
      text: startCase(JobStatus.NOT_STARTED),
      value: JobStatus.NOT_STARTED,
      label: 'red',
    },
    {
      text: startCase(JobStatus.IN_PROGRESS),
      value: JobStatus.IN_PROGRESS,
      label: 'yellow',
    },
    {
      text: startCase(JobStatus.COMPLETED),
      value: JobStatus.COMPLETED,
      label: 'green',
    },
  ];

  public static readonly PostStageOptions = [
    {
      text: startCase(JobStatus.NOT_STARTED),
      value: JobStatus.NOT_STARTED,
      label: 'red',
    },
    {
      text: startCase(JobStatus.IN_PROGRESS),
      value: JobStatus.IN_PROGRESS,
      label: 'yellow',
    },
    {
      text: startCase(JobStatus.NOT_COMPLETED),
      value: JobStatus.NOT_COMPLETED,
      label: 'orange',
    },
    {
      text: startCase(JobStatus.COMPLETED_UNPAID),
      value: JobStatus.COMPLETED_UNPAID,
      label: 'blue',
    },
    {
      text: startCase(JobStatus.COMPLETED_PAID),
      value: JobStatus.COMPLETED_PAID,
      label: 'green',
    },
  ];

  public static readonly ServiceStageOptions = [
    {
      text: startCase(JobStatus.NOT_STARTED),
      value: JobStatus.NOT_STARTED,
      label: 'red',
    },
    {
      text: startCase(JobStatus.IN_PROGRESS),
      value: JobStatus.IN_PROGRESS,
      label: 'yellow',
    },
    {
      text: startCase(JobStatus.NOT_COMPLETED),
      value: JobStatus.NOT_COMPLETED,
      label: 'orange',
    },
    {
      text: startCase(JobStatus.COMPLETED),
      value: JobStatus.COMPLETED,
      label: 'green',
    },
  ];

  public static readonly PricingStatusOptions: DropdownOption[] = [
    {
      key: PricingItemStatus.SENT,
      text: `${startCase(
        PricingItemStatus.SENT,
      )} - Will create an invoice at Xero/Quickbooks (if applicable)`,
      value: PricingItemStatus.SENT,
    },
    {
      key: PricingItemStatus.PAID,
      text: 'Payment received',
      value: PricingItemStatus.PAID,
    },
    {
      key: PricingItemStatus.WRITTEN_OFF,
      text: 'Discounted, refunded or written off',
      value: PricingItemStatus.WRITTEN_OFF,
    },
  ];

  public static readonly MediaDisplayInReportOptions: DropdownOption[] = [
    {
      key: 'media-display-in-reports-true',
      text: 'Yes, display in reports',
      value: 'true',
    },
    {
      key: 'media-display-in-reports-false',
      text: 'No, hide media',
      value: 'false',
    },
  ];
}
