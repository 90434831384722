import {
  faBuilding,
  faCreditCard,
  faKeyboard,
  faMapPin,
  faPaperclip,
  faPollH,
  faPoundSign,
  faTasks,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import axios from 'axios';
import ObjectId from 'bson-objectid';
import {cloneDeep, startCase} from 'lodash';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {DropdownItemProps} from 'semantic-ui-react';
import config from '../../config/config';
import {DataType, EventType} from '../../const/crud';
import CustomModalProps from '../../const/modal';
import {
  Job,
  JobEnums,
  jobInitialState,
  JobStage,
  jobStageMap,
  JobStatus,
  JobType,
  JobTypeOptions,
  JobVATOptions,
  Stage,
} from '../../models/Job';
import {UserRole} from '../../models/User';
import {useAuth0} from '../../react-auth0-spa';
import ActionHelper from '../../utils/actionHelper';
import {DataHelper, ValueTemplate} from '../../utils/dataHelper';
import MediaHelper from '../../utils/mediaHelper';
import {ModalType} from '../../utils/modalHelper';
import Checklist from '../Checklist';
import Cost from '../Cost';
import {
  CRUDType,
  DataActionType,
  DataFormType,
  DataTableType,
  Section,
  SortDirection,
  ValidationType,
} from '../Data/DataConst';
import DataModal from '../Data/DataModal';
import DataTable from '../Data/DataTable';
import LocationStagePicker from '../LocationStagePicker';
import SignatureView from '../SignatureView';

export const CUSTOM_FIELD_PREFIX = 'ONS-CF-';

interface JobModalProps extends CustomModalProps {
  actionHelper: ActionHelper;
  callback?: any;
  setPromptModal: any;
}

const stageMap = {
  0: 'pos',
  1: 'pre',
  2: 'admin',
  3: 'post',
  4: 'service',
};

const JobModal: React.FunctionComponent<JobModalProps> = (
  props: JobModalProps,
) => {
  const {getTokenSilently} = useAuth0();
  const [activeStageIndex, setActiveStageIndex] = useState<number>();
  const [formData, setFormData] = useState(props.selected.data);
  const [fetchingUserJobs, setFetchingUserJobs] = useState<boolean>(true);
  const [disableDates, setDisableDates] = useState<
    {userId: string; dates: string[]}[]
  >([]);

  const [assignedUsers, setAssignedUsers] = useState<any>([]);

  // Set default stage tab
  useEffect(() => {
    if (formData?.selected?.stage && typeof activeStageIndex === 'undefined') {
      setStage(formData.selected?.stage);
    }
  }, [activeStageIndex, formData?.selected]);

  // On stage changing, set assigned users
  useEffect(() => {
    if (formData?.selected) {
      const stage = formData.selected.stages[stageMap[activeStageIndex]];
      if (stage) {
        setAssignedUsers((stage as Stage).assignedTo);
        setFetchingUserJobs(false);
      }
    }
  }, [activeStageIndex, formData?.selected]);

  // Fetch user assigned jobs
  useEffect(() => {
    if (!fetchingUserJobs && assignedUsers.length && formData?.selected) {
      setFetchingUserJobs(true);
      (async () => {
        const token = await getTokenSilently();

        const start = moment()
          .subtract(1, 'month')
          .toISOString();

        const params = {
          userIds: assignedUsers,
          _id: {$ne: formData.selected._id},
          $or: JSON.stringify([
            {
              $and: [
                {'stages.pos.start': {$gte: start}},
                {'stages.pos.assignedTo': {$in: assignedUsers}},
              ],
            },
            {
              $and: [
                {'stages.pre.start': {$gte: start}},
                {'stages.pre.assignedTo': {$in: assignedUsers}},
              ],
            },
            {
              $and: [
                {'stages.admin.start': {$gte: start}},
                {'stages.admin.assignedTo': {$in: assignedUsers}},
              ],
            },
            {
              $and: [
                {'stages.post.start': {$gte: start}},
                {'stages.post.assignedTo': {$in: assignedUsers}},
              ],
            },
            {
              $and: [
                {'stages.service.start': {$gte: start}},
                {'stages.service.assignedTo': {$in: assignedUsers}},
              ],
            },
          ]),
        };

        const userDates = await axios.get(
          `${config.api}/job/assigned-dates/?company=${props.loggedInUser.company}`,
          {
            headers: {Authorization: 'Bearer ' + token},
            params,
          },
        );

        const enumerateDaysBetweenDates = (startDate, endDate) => {
          const dates = [];

          const currDate = moment(startDate).startOf('day');
          const lastDate = moment(endDate).startOf('day');

          while (currDate.add(5, 'minutes').diff(lastDate) < 0) {
            dates.push(currDate.clone().toDate());
          }

          return [
            moment(startDate).toDate(),
            ...dates,
            moment(endDate).toDate(),
          ];
        };

        const staffAvailabilityDates = props.loggedInCompany.staffAvailability.reduce(
          (acc, log) => {
            const dates =
              moment(log.startDate).isSame(log.endDate, 'year') &&
              moment(log.startDate).isSame(log.endDate, 'month') &&
              moment(log.startDate).isSame(log.endDate, 'day')
                ? [
                    {
                      userId: log.userId,
                      dates: moment(log.startDate).toDate(),
                    },
                  ]
                : enumerateDaysBetweenDates(
                    log.startDate,
                    log.endDate,
                  ).map((d) => ({userId: log.userId, dates: d}));

            return [...acc, ...dates];
          },
          [],
        );

        if (userDates?.data) {
          setDisableDates([
            ...userDates.data.data.map((date) => ({
              userId: assignedUsers[0],
              dates: date,
            })),
            ...staffAvailabilityDates,
          ]);
        }
      })();
    }
  }, [
    assignedUsers,
    fetchingUserJobs,
    formData?.selected,
    formData?.selected._id,
    getTokenSilently,
    props.loggedInCompany.staffAvailability,
    props.loggedInUser.company,
  ]);

  const handleDataAction = async (
    action: DataActionType,
    dataId: string,
    data: any,
  ) => {
    await props.actionHelper.handleDataAction(
      DataType.JOB,
      (state) => props.setSelected(state),
      action,
      props.selected._id,
      dataId,
      [
        DataActionType.EDIT_CHECKLIST,
        DataActionType.EDIT_PAYMENT,
        DataActionType.EDIT_COST,
        DataActionType.EDIT_MEDIA,
      ].includes(action)
        ? {selected: props.selected, data}
        : data,
    );
  };

  const resetDataForm = () => {
    setActiveStageIndex(null);
    // Reset search options
    props.setSearchOptions({});

    // Reset JobId
    jobInitialState.jobId = '';

    // Set company
    jobInitialState.company = props.loggedInUser.company;

    props.setSelected(jobInitialState);
    props.setDataForm(false);
  };

  const alertAssigned = (formData, stage) => {
    const selectedStage = formData.selected.stages[stage];
    if (selectedStage.signature && selectedStage.signature.length) {
      const lastSig =
        selectedStage.signature[selectedStage.signature.length - 1];

      if (lastSig.status !== selectedStage.status) {
        const modalData = {
          modalType: ModalType.WARNING,
          title: 'Job Stage Status Updated',
          body:
            "You have updated this stage's status. Remember to check that it has been assigned to the correct onsite users!",
          action: {
            title: 'Confirm',
            action: {
              type: 'close',
              dataType: DataType.JOB,
              documentId: props.selected._id,
              dataSetter: props.setSelected,
            },
          },
        };

        props.setPromptModal(
          modalData.modalType,
          {status: true},
          modalData.title,
          modalData.body,
          modalData.action,
        );
      }
    }
  };

  const addEvents = (data) => {
    const propsData = props.selected;

    if (propsData.stage !== data.stage) {
      data.stages[data.stage].events.push({
        _id: new ObjectId().toHexString(),
        created: new Date(),
        createdBy: props.loggedInUser._id,
        type: EventType.STATUS_UPDATE,
        value: data.stage,
      });
    }

    const options = JobEnums.JobStageOptions(props.loggedInCompany.stageTitles);
    options.map((stage) => {
      if (
        (!propsData.stages[stage.value] &&
          data.stages[stage.value].assignedTo) ||
        (propsData.stages[stage.value] &&
          propsData.stages[stage.value].assignedTo !==
            data.stages[stage.value].assignedTo)
      ) {
        data.stages[stage.value].events.push({
          _id: new ObjectId().toHexString(),
          created: new Date(),
          createdBy: props.loggedInUser._id,
          type: EventType.ASSIGNMENT,
          value: data.stages[stage.value].assignedTo,
        });
      }
      return null;
    });

    return data;
  };

  const filterStageStatuses = (stage: JobStage) => {
    switch (stage) {
      case JobStage.POS:
        if (
          formData &&
          formData.selected &&
          formData.selected.jobType === JobType.COMMERCIAL
        ) {
          return JobEnums.PreStageOptions;
        }
        return JobEnums.PosStageOptions;
      case JobStage.PRE:
        return JobEnums.PreStageOptions;
      case JobStage.ADMIN:
        return JobEnums.AdminStageOptions;
      case JobStage.POST:
        if (
          formData &&
          formData.selected &&
          formData.selected.jobType === JobType.COMMERCIAL
        ) {
          return JobEnums.PreStageOptions;
        }
        return JobEnums.PostStageOptions;
      case JobStage.SERVICE:
        return JobEnums.ServiceStageOptions;
    }
  };

  const mergeDataSets = () => {
    const merged = cloneDeep(props.userOptions);

    if (props.searchOptions && props.searchOptions[DataType.USER]) {
      props.searchOptions[DataType.USER].forEach((opt) => {
        const found = merged.find((i) => i.value === opt.value);
        if (!found) {
          merged.push(opt);
        }
      });
    }
    return merged;
  };

  const setStage = (stage) => {
    const index = JobEnums.JobStages.indexOf(stage);
    if (index !== -1) {
      setActiveStageIndex(index);
    }
  };

  const formatCustomerForPdf = () => {
    const cus =
      props.customers &&
      props.customers.find((c) => c._id === props.selected.customer);

    return {
      billing: cus?.contact.find(
        (c) => c._id === props.selected?.contact.billing,
      ),
      installation: cus?.contact.find(
        (c) => c._id === props.selected?.contact.installation,
      ),
    };
  };

  const getModalStageSections = (): Section[] => {
    return JobEnums.JobStages.map((stage) => {
      const stageData = formData?.selected.stages || props.selected.stages;

      if (typeof activeStageIndex === 'undefined' || activeStageIndex == null) {
        setStage(props.selected.stage);
      }

      let fields = [
        {
          dataField: 'stage',
          type: DataFormType.BUTTON,
          optional: 'hidden',
          dataSet: {
            stage,
            buttonText: 'Set As Active Stage',
            activeButtonText: 'Current Active Stage',
          },
        },
        {
          title: 'Assign Stage To',
          description:
            "Type an onsite user's name to search and assign users to this job. Multiple users can be assigned.",
          placeholder: 'Type to search Users',
          dataField: `stages.${[stage]}.assignedTo`,
          dataSet: mergeDataSets(),
          dataType: DataType.USER,
          type: DataFormType.SEARCH_SELECT_API_MULTIPLE,
          optional: true,
        },
        {
          className: 'halfWidth',
          title: 'Start Date/Time',
          content: {label: 'All Day Event'},
          value:
            moment(props.selected.stages[stage].start).format('HH:mm') ===
            '00:00',
          dataField: `stages.${[stage]}.startAllDay`,
          dataSet: {
            dataField: `stages.${[stage]}.start`,
            disable: disableDates,
            selectedStage: stage,
            staffAvailability: props.loggedInCompany.staffAvailability?.map(
              (log) => {
                const user = props.users.find(
                  (user) => user._id === log.userId,
                );

                if (!user) {
                  return log;
                }

                return {
                  ...log,
                  userName: `${user.firstName} ${user.lastName}`,
                };
              },
            ),
          },
          type: DataFormType.DATE_SWITCH,
          optional: true,
        },
        {
          className: 'halfWidth',
          title: 'Finish Date/Time',
          content: {label: 'All Day Event'},
          value:
            moment(props.selected.stages[stage].due).format('HH:mm') ===
            '23:59',
          dataField: `stages.${[stage]}.dueAllDay`,
          dataSet: {
            dataField: `stages.${[stage]}.due`,
            disable: disableDates,
            selectedStage: stage,
            staffAvailability: props.loggedInCompany.staffAvailability?.map(
              (log) => {
                const user = props.users.find(
                  (user) => user._id === log.userId,
                );

                if (!user) {
                  return log;
                }

                return {
                  ...log,
                  userName: `${user.firstName} ${user.lastName}`,
                };
              },
            ),
          },
          type: DataFormType.DATE_SWITCH,
          optional: true,
        },
        {
          title: 'Current Status',
          description:
            "To allow an onsite user to start this stage from their mobile app, make sure this status is set to 'Not Started'.",
          dataField: `stages.${[stage]}.status`,
          dataSet: filterStageStatuses(stage),
          type: DataFormType.SELECT,
          validation: [ValidationType.REQUIRED],
          label: true,
          optional: true,
          onChange: (formData) => alertAssigned(formData, stage),
        },
        {
          dataField: `stage.${[stage]}`,
          type: DataFormType.TABLE,
          content: (
            <LocationStagePicker
              stage={stage}
              stageData={stageData}
              handleDataAction={handleDataAction}
              selectedJob={props.selected}
              users={props.users}
              loggedInCompany={props.loggedInCompany}
            />
          ),
        },
        {
          dataField: 'checklist',
          type: DataFormType.TABLE,
          content: (
            <Checklist
              stage={stage}
              stageData={stageData}
              handleDataAction={handleDataAction}
              users={props.users}
              loggedInCompany={props.loggedInCompany}
              loggedInUser={props.loggedInUser}
              state={props.selected}
            />
          ),
        },
        {
          placeholder: 'Select Response',
          title: 'Changes to initial customer contract/order?',
          dataField: `stages.${[stage]}.additional.contractChanges`,
          type: DataFormType.SELECT,
          dataSet: [
            {text: 'Yes', value: true},
            {text: 'No', value: false},
          ],
          hidden: ![JobStage.PRE, JobStage.POST].includes(stage),
          optional: true,
        },
        {
          title: 'Who suggested change & provide details of change',
          dataField: `stages.${[stage]}.additional.contractChangeDetails`,
          type: DataFormType.TEXT_AREA,
          optional: true,
          hidden: ![JobStage.PRE, JobStage.POST].includes(stage),
        },
        {
          placeholder: 'Select Response',
          title: 'Have there been delays on this job?',
          dataField: `stages.${[stage]}.additional.delays`,
          type: DataFormType.SELECT,
          value: 'false',
          dataSet: [
            {text: 'Yes', value: true},
            {text: 'No', value: false},
          ],
          hidden: ![JobStage.POST].includes(stage),
          optional: true,
        },
        {
          title: 'Provide reasons why',
          dataField: `stages.${[stage]}.additional.delayDetails`,
          type: DataFormType.TEXT_AREA,
          optional: true,
          hidden: ![JobStage.POST].includes(stage),
        },
        {
          dataField: `stages.${[stage]}.signature`,
          type: DataFormType.TABLE,
          content: (
            <SignatureView
              signature={() =>
                stageData[stage].signature.map((signature) => {
                  const sig = cloneDeep(signature);
                  if (!sig) {
                    return null;
                  }
                  sig.createdBy = DataHelper.getDataFromOptions(
                    sig.createdBy,
                    props.userOptions,
                  );
                  return sig;
                })
              }
              stage={stage}
              stageObj={stageData}
              loggedInCompany={props.loggedInCompany}
              jobType={formData && formData.selected.jobType}
            />
          ),
        },
      ];

      if (stage === JobStage.ADMIN) {
        fields = fields.filter((f) =>
          [
            `stage`,
            `stages.${[stage]}.startAllDay`,
            `stages.${[stage]}.dueAllDay`,
            `stages.${[stage]}.assignedTo`,
            `stages.${[stage]}.status`,
            `stage.${[stage]}`,
            'checklist',
          ].includes(f.dataField),
        );
      }

      const section = {
        scrollable: true,
        scrollTitle: 'Job Stage',
        tab: 'stages',
        tabTitle: 'Job Stage',
        titleIcon: <FontAwesomeIcon icon={faPollH} />,
        description:
          'Choose a Start Date/Time for each Job Stage and then ' +
          'Assign each Stage to the relevant Onsite User.',
        selectedTab: activeStageIndex,
        onTabChange: (index) => setActiveStageIndex(index),
        title: stage,
        fields: fields,
      } as Section;

      if (
        props.selected.stages[stage] &&
        ((props.selected.stages[stage].signature &&
          props.selected.stages[stage].signature.length) ||
          JobEnums.CompletedJobStatuses.includes(
            props.selected.stages[stage].status,
          ))
      ) {
        section.fields.push({
          title: `${jobStageMap(
            stage,
            props.loggedInCompany.stageTitles,
          )} Completion Report`,
          type: DataFormType.BUILD_PDF,
          dataField: 'stage',
          dataSet: {
            buttonTitle: 'Generate Completion Report',
            buttonTitleView: 'View Completion Report',
            setter: (state) => props.setSelected(state),
            data: props.selected,
            stage: stage,
            loggedInUser: props.loggedInUser._id,
            loggedInCompany: props.loggedInCompany,
            action: props.crudAction,
            customer:
              props.customers &&
              props.customers.find((c) => c._id === props.selected.customer),
            contact: formatCustomerForPdf(),
            users: props.users,
            company:
              (props.companies &&
                props.companies.find(
                  (c) => c._id === props.selected.company,
                )) ||
              props.loggedInCompany,
          },
          optional: 'hidden',
        });
      }

      return section;
    });
  };

  const createJobFormSections = (): Section[] => {
    const sections: Section[] = [
      {
        scrollable: false,
        title: 'Company Details',
        titleIcon: <FontAwesomeIcon icon={faBuilding} />,
        hideSection: props.loggedInUser.role !== UserRole.SUPER_ADMIN,
        fields: [
          {
            title: 'Company',
            dataField: 'company',
            dataSet:
              props.searchOptions[DataType.COMPANY] &&
              props.searchOptions[DataType.COMPANY].length
                ? props.searchOptions[DataType.COMPANY]
                : props.companyOptions,
            dataType: DataType.COMPANY,
            type: DataFormType.SEARCH_SELECT_API,
            validation: [ValidationType.REQUIRED],
          },
        ],
      },
      {
        scrollable: true,
        scrollTitle: 'Job Type',
        title: 'Job Type',
        titleIcon: <FontAwesomeIcon icon={faBuilding} />,
        fields: [
          {
            title: 'Commercial or Domestic',
            description: `If 'Commercial' is selected an onsite user will be 
            required to sign off the job, otherwise a customer signature will 
            be required.`,
            dataField: 'jobType',
            dataSet: JobTypeOptions,
            type: DataFormType.SELECT,
            validation: [ValidationType.REQUIRED],
          },
          {
            title: 'VAT Applicable',
            dataField: 'vat',
            dataSet: JobVATOptions,
            type: DataFormType.SELECT,
            optional: true,
          },
          {
            hidden: !props.loggedInCompany.leadSources?.length,
            title: 'Lead Source',
            placeholder: 'Select a predefined Lead Source',
            dataField: 'leadSource',
            type: DataFormType.SELECT,
            optional: true,
            dataSet: props.loggedInCompany.leadSources.reduce(
              (acc: DropdownItemProps[], ls, i) => {
                if (!acc.find((v) => v.value === ls.name)) {
                  return [
                    ...acc,
                    {
                      key: `lead-source-${ls.name}-${i}`,
                      value: ls.name,
                      text: ls.name,
                    },
                  ];
                }
                return acc;
              },
              [],
            ),
          },
        ],
      },
      {
        scrollable: true,
        scrollTitle: 'Job Details',
        title: 'Job Details',
        titleIcon: <FontAwesomeIcon icon={faTasks} />,
        fields: [
          {
            className: 'thirdWidth',
            title: 'Created Date',
            placeholder: 'Select a predefined Job ID Prefix',
            dataField: 'created',
            type: DataFormType.DATE,
            hidden: props.loggedInUser.role !== UserRole.SUPER_ADMIN,
            disabled: 'true',
          },
          {
            className: 'thirdWidth',
            title: 'Job ID Prefix',
            placeholder: 'Select a predefined Job ID Prefix',
            dataField: 'jobPrefix',
            type: DataFormType.SELECT,
            optional: true,
            dataSet: props.loggedInCompany.jobCodes.reduce(
              (acc: DropdownItemProps[], jc, i) => {
                if (!acc.find((v) => v.value === jc.name)) {
                  return [
                    ...acc,
                    {
                      key: `prefix-${jc.name}-${i}`,
                      value: jc.name,
                      text: jc.name,
                    },
                  ];
                }
                return acc;
              },
              [],
            ),
          },
          {
            className: 'thirdWidth',
            title: 'Job ID',
            placeholder: 'Enter a unique Job ID number',
            dataField: 'jobId',
            type: DataFormType.TEXT,
            optional: true,
            dataSet: {
              handleDataAction: handleDataAction,
            },
          },
          {
            className: 'thirdWidth',
            title: 'Job Type',
            placeholder:
              'E.g. Electrical Work, Building Work, Window Replacement etc.',
            dataField: 'type',
            type: DataFormType.TEXT,
            validation: [ValidationType.REQUIRED],
          },
          {
            title: 'Job Description',
            dataField: 'description',
            type: DataFormType.TEXT_AREA,
            optional: true,
          },
          {
            title: 'Assign Job To Customer',
            description:
              'Type a customer name to search and assign a customer.',
            dataField: 'customer',
            dataSet:
              props.searchOptions[DataType.CUSTOMER] &&
              props.searchOptions[DataType.CUSTOMER].length
                ? props.searchOptions[DataType.CUSTOMER]
                : props.customerOptions,
            dataType: DataType.CUSTOMER,
            type: DataFormType.SEARCH_SELECT_API_CUSTOMER,
            validation: [ValidationType.REQUIRED],
          },
          {
            disabled: 'customer',
            className: 'halfWidth',
            title: 'Installation Address',
            description: 'Select an installation address.',
            dataField: 'contact.installation',
            dataSubField: 'customer',
            dataSet:
              props.searchOptions[DataType.CUSTOMER] &&
              props.searchOptions[DataType.CUSTOMER].length
                ? props.searchOptions[DataType.CUSTOMER]
                : props.customerOptions,
            dataType: DataType.CUSTOMER,
            type: DataFormType.SELECT_CONTACT,
            validation: [ValidationType.REQUIRED],
            lookup: {
              dataSet: props.customers,
              field: '_id',
            },
          },
          {
            disabled: 'customer',
            className: `halfWidth`,
            title: 'Billing Address',
            description: 'Select a billing address.',
            dataField: 'contact.billing',
            dataSubField: 'customer',
            dataSet:
              props.searchOptions[DataType.CUSTOMER] &&
              props.searchOptions[DataType.CUSTOMER].length
                ? props.searchOptions[DataType.CUSTOMER]
                : props.customerOptions,
            dataType: DataType.CUSTOMER,
            type: DataFormType.SELECT_CONTACT,
            validation: [ValidationType.REQUIRED],
            lookup: {
              dataSet: props.customers,
              field: '_id',
            },
          },
        ],
      },

      {
        hideSection: !props.loggedInUser?.permissions?.financial?.job?.pricing,
        scrollable: true,
        scrollTitle: 'Job Pricing',
        title: 'Job Pricing',
        titleIcon: <FontAwesomeIcon icon={faPoundSign} />,
        fields: [
          {
            className: 'thirdWidth',
            title: `Total Job Value`,
            dataField: 'price.value',
            type: DataFormType.PRICE_TOTAL,
            optional: 'hidden',
          },
          {
            className: 'thirdWidth',
            title: `VAT`,
            dataField: 'price.value',
            type: DataFormType.PRICE_VAT,
            optional: 'hidden',
          },
          {
            className: 'thirdWidth',
            title: `Net Job Value`,
            dataField: 'price.value',
            type: DataFormType.PRICE_NET,
            optional: 'hidden',
          },
          {
            className: 'halfWidth',
            title: 'Owed',
            dataField: 'price.due',
            type: DataFormType.PRICE_OWED,
            optional: 'hidden',
          },
          {
            className: 'halfWidth',
            title: 'Invoiced',
            dataField: 'price.due',
            type: DataFormType.PRICE_INVOICED,
            optional: 'hidden',
          },
          {
            className: 'halfWidth',
            title: 'Payment received',
            dataField: 'price.deposit',
            type: DataFormType.PRICE_PAID,
            optional: 'hidden',
          },
          {
            className: 'halfWidth',
            title: 'Discounted, Refunded or Written Off',
            dataField: 'price.due',
            type: DataFormType.PRICE_WRITTEN_OFF,
            optional: 'hidden',
          },
        ],
      },

      {
        hideSection: !props.loggedInUser?.permissions?.financial?.job?.payments,
        scrollable: true,
        scrollTitle: 'Payments & Invoices',
        fields: [
          {
            dataField: 'pricing',
            type: DataFormType.TABLE,
            content: (
              <>
                <DataTable
                  additional={formData}
                  title="Payments & Invoices"
                  description={
                    'Track payments for this job and automatically create invoices (requires add-on).'
                  }
                  titleIcon={<FontAwesomeIcon icon={faCreditCard} />}
                  data={props.selected.pricing}
                  dataType={DataType.PAYMENT}
                  primaryAction={DataActionType.CREATE_PAYMENT}
                  handleAction={handleDataAction}
                  crudAction={props.crudAction}
                  pageSize={5}
                  sortField={['created']}
                  sortDirection={[SortDirection.DESC]}
                  actions={[
                    {
                      title: 'Edit',
                      icon: 'pencil',
                      className: 'spaceBelow',
                      action: DataActionType.EDIT_PAYMENT,
                    },
                    {
                      title: 'Delete',
                      icon: 'delete',
                      className: 'redButton',
                      action: DataActionType.DELETE_PAYMENT,
                      data: props.selected.pricing,
                    },
                  ]}
                  cols={[
                    {
                      title: 'Payment Name or Number',
                      dataField: 'paymentNumber',
                      type: DataTableType.STRING,
                      mobileDisplay: true,
                      width: 3,
                    },
                    {
                      title: 'Payment Amount',
                      dataField: 'amount',
                      type: DataTableType.CURRENCY,
                      mobileDisplay: true,
                      width: 3,
                    },
                    {
                      title: 'Status',
                      dataField: 'status',
                      type: DataTableType.STRING,
                      mobileDisplay: true,
                      width: 3,
                    },
                    {
                      title: 'Invoice Number',
                      dataField: 'invoiceNumber',
                      type: DataTableType.STRING,
                      mobileDisplay: true,
                      width: 3,
                    },
                    {
                      title: 'Description',
                      dataField: 'description',
                      type: DataTableType.STRING,
                      mobileDisplay: true,
                      width: 3,
                    },
                    {
                      title: 'Invoice',
                      dataField: 'invoice',
                      type: DataTableType.BUILD_INVOICE_PDF,
                      mobileDisplay: true,
                      width: 3,
                    },
                  ]}
                />
              </>
            ),
          },
        ],
      },

      {
        hideSection: !props.loggedInUser?.permissions?.financial?.job?.costs,
        scrollable: true,
        scrollTitle: 'Job Costs',
        title: 'Job Costs',
        titleIcon: <FontAwesomeIcon icon={faWrench} />,
        fields: [
          {
            dataField: 'checklist',
            type: DataFormType.TABLE,
            content: (
              <Cost
                data={formData?.selected}
                handleDataAction={handleDataAction}
                users={props.users}
                loggedInCompany={props.loggedInCompany}
                loggedInUser={props.loggedInUser}
                state={props.selected}
              />
            ),
          },
          {
            title: `Cost Report`,
            type: DataFormType.BUILD_COST_PDF,
            dataField: 'costs',
            dataSet: {
              buttonTitle: 'Generate Completion Report',
              buttonTitleView: 'View Completion Report',
              setter: (state) => props.setSelected(state),
              data: formData?.selected,
              loggedInUser: props.loggedInUser._id,
              loggedInCompany: props.loggedInCompany,
              action: props.crudAction,
              customer:
                props.customers &&
                props.customers.find(
                  (c) =>
                    c._id ===
                    (formData?.selected?.customer || props.selected.customer),
                ),
              contact: formatCustomerForPdf(),
              users: props.users,
              company:
                props.companies &&
                props.companies.find((c) => c._id === props.selected.company),
            },
            optional: 'hidden',
          },
        ],
      },

      {
        scrollable: true,
        scrollTitle: 'Attachments',
        fields: [
          {
            dataField: 'attachments',
            type: DataFormType.TABLE,
            content: (
              <div className={'DataAttachmentsContainer'}>
                <div className={'DataTableTitleContainer'}>
                  <FontAwesomeIcon icon={faPaperclip} />
                  <h1>Attachments</h1>
                </div>
                <DataTable
                  style={{
                    border: `1px solid #2ecc71`,
                    padding: 20,
                    borderRadius: 5,
                  }}
                  title="Admin Attachments"
                  description={
                    'Add sensitive documents here For Office/Manager/Owner ' +
                    'Use Only.'
                  }
                  data={props.selected.attachments.filter(
                    (a) => a.access === UserRole.ADMIN,
                  )}
                  dataType={DataType.ATTACHMENT}
                  primaryAction={DataActionType.CREATE_ATTACHMENT}
                  primaryActionTitle={'Add Admin Attachment'}
                  primaryActionClass={'greenButton'}
                  handleAction={(action: DataActionType, dataId: string) => {
                    handleDataAction(action, dataId, {access: UserRole.ADMIN});
                  }}
                  pageSize={5}
                  sortField={['created']}
                  sortDirection={[SortDirection.DESC]}
                  actions={[
                    {
                      title: 'Delete',
                      icon: 'delete',
                      className: 'redButton',
                      action: DataActionType.DELETE_ATTACHMENT,
                      data: props.selected.attachments,
                    },
                  ]}
                  cols={[
                    {
                      title: 'Attachment Name',
                      dataField: 'title',
                      type: DataTableType.PLAIN,
                      mobileDisplay: true,
                      width: 4,
                    },
                    {
                      title: 'Created Date',
                      dataField: 'created',
                      type: DataTableType.DATE_TIME,
                      mobileDisplay: true,
                      width: 3,
                    },
                    {
                      title: 'Created By',
                      dataField: 'createdBy',
                      dataProcess: {
                        action: DataHelper.getValueFromData,
                        data: props.users,
                        field: ValueTemplate.NAME,
                      },
                      type: DataTableType.STRING,
                      mobileDisplay: false,
                      width: 3,
                    },
                    {
                      title: 'Click To View',
                      dataField: 'mediaId',
                      dataProcess: {
                        action: MediaHelper.getMediaUrl,
                        options: [],
                      },
                      type: DataTableType.ATTACHMENT,
                      mobileDisplay: true,
                      width: 2,
                    },
                  ]}
                />
                <DataTable
                  style={{
                    border: `1px solid #3498db`,
                    padding: 20,
                    borderRadius: 5,
                  }}
                  title="Onsite User Attachments"
                  description={
                    'Add documents for Onsite Users to see here, and view ' +
                    'attachments added from site.'
                  }
                  data={props.selected.attachments.filter(
                    (a) => a.access === UserRole.USER,
                  )}
                  dataType={DataType.ATTACHMENT}
                  primaryAction={DataActionType.CREATE_ATTACHMENT}
                  primaryActionTitle={'Add User Attachment'}
                  primaryActionClass={'blueButton'}
                  handleAction={(action: DataActionType, dataId: string) => {
                    handleDataAction(action, dataId, {access: UserRole.USER});
                  }}
                  pageSize={5}
                  sortField={['created']}
                  sortDirection={[SortDirection.DESC]}
                  actions={[
                    {
                      title: 'Delete',
                      icon: 'delete',
                      className: 'redButton',
                      action: DataActionType.DELETE_ATTACHMENT,
                      data: props.selected.attachments,
                    },
                  ]}
                  cols={[
                    {
                      title: 'Attachment Name',
                      dataField: 'title',
                      type: DataTableType.PLAIN,
                      mobileDisplay: true,
                      width: 4,
                    },
                    {
                      title: 'Created Date',
                      dataField: 'created',
                      type: DataTableType.DATE_TIME,
                      mobileDisplay: true,
                      width: 3,
                    },
                    {
                      title: 'Created By',
                      dataField: 'createdBy',
                      dataProcess: {
                        action: DataHelper.getValueFromData,
                        data: props.users,
                        field: ValueTemplate.NAME,
                      },
                      type: DataTableType.STRING,
                      mobileDisplay: false,
                      width: 3,
                    },
                    {
                      title: 'Click To View',
                      dataField: 'mediaId',
                      dataProcess: {
                        action: MediaHelper.getMediaUrl,
                        options: [],
                      },
                      type: DataTableType.ATTACHMENT,
                      mobileDisplay: true,
                      width: 2,
                    },
                  ]}
                />
                <DataTable
                  style={{
                    border: `1px solid #f39c12`,
                    padding: 20,
                    borderRadius: 5,
                  }}
                  title="Customer Attachments"
                  description={
                    'Attach customer documents here, such as invoices, ' +
                    'warranty certificates & completion paperwork.'
                  }
                  data={props.selected.attachments.filter(
                    (a) => a.access === UserRole.CUSTOMER,
                  )}
                  dataType={DataType.ATTACHMENT}
                  primaryAction={DataActionType.CREATE_ATTACHMENT}
                  primaryActionTitle={'Add Customer Attachment'}
                  primaryActionClass={'orangeButton'}
                  handleAction={(action: DataActionType, dataId: string) => {
                    handleDataAction(action, dataId, {
                      access: UserRole.CUSTOMER,
                    });
                  }}
                  pageSize={5}
                  sortField={['created']}
                  sortDirection={[SortDirection.DESC]}
                  actions={[
                    {
                      title: 'Delete',
                      icon: 'delete',
                      className: 'redButton',
                      action: DataActionType.DELETE_ATTACHMENT,
                      data: props.selected.attachments,
                    },
                  ]}
                  cols={[
                    {
                      title: 'Attachment Name',
                      dataField: 'title',
                      type: DataTableType.PLAIN,
                      mobileDisplay: true,
                      width: 4,
                    },
                    {
                      title: 'Created Date',
                      dataField: 'created',
                      type: DataTableType.DATE_TIME,
                      mobileDisplay: true,
                      width: 3,
                    },
                    {
                      title: 'Created By',
                      dataField: 'createdBy',
                      dataProcess: {
                        action: DataHelper.getValueFromData,
                        data: props.users,
                        field: ValueTemplate.NAME,
                      },
                      type: DataTableType.STRING,
                      mobileDisplay: false,
                      width: 3,
                    },
                    {
                      title: 'Click To View',
                      dataField: 'mediaId',
                      dataProcess: {
                        action: MediaHelper.getMediaUrl,
                        options: [],
                      },
                      type: DataTableType.ATTACHMENT,
                      mobileDisplay: true,
                      width: 2,
                    },
                  ]}
                />
              </div>
            ),
          },
        ],
      },

      {
        scrollable: true,
        scrollTitle: 'Locations',
        fields: [
          {
            dataField: 'locations',
            type: DataFormType.TABLE,
            content: (
              <DataTable
                title="Locations"
                description={
                  'Create multiple Onsite locations e.g. Bedroom 1, ' +
                  'Kitchen etc. Onsite users can then add images, videos and ' +
                  'notes to each location.'
                }
                titleIcon={<FontAwesomeIcon icon={faMapPin} />}
                data={props.selected.locations}
                dataType={DataType.LOCATION}
                primaryAction={DataActionType.CREATE_LOCATION}
                handleAction={handleDataAction}
                pageSize={5}
                sortField={['created']}
                sortDirection={[SortDirection.DESC]}
                actions={[
                  {
                    title: 'Delete',
                    icon: 'delete',
                    className: 'redButton',
                    action: DataActionType.DELETE_LOCATION,
                    data: props.selected.locations,
                  },
                ]}
                cols={[
                  {
                    title: 'Location Name',
                    dataField: 'location',
                    type: DataTableType.STRING,
                    mobileDisplay: true,
                    width: 9,
                  },
                  {
                    title: 'Created Date',
                    dataField: 'created',
                    type: DataTableType.DATE_TIME,
                    mobileDisplay: true,
                    width: 3,
                  },
                  {
                    title: 'Created By',
                    dataField: 'createdBy',
                    dataProcess: {
                      action: DataHelper.getValueFromData,
                      data: props.users,
                      field: ValueTemplate.NAME,
                    },
                    type: DataTableType.STRING,
                    mobileDisplay: false,
                    width: 3,
                  },
                ]}
              />
            ),
          },
        ],
      },

      {
        scrollable: true,
        scrollTitle: 'Custom Fields',
        title: 'Custom Fields',
        titleIcon: <FontAwesomeIcon icon={faKeyboard} />,
        hideSection: !props.loggedInCompany.customFields?.length,
        fields: props.loggedInCompany.customFields.map((field) => ({
          title: field.name,
          dataField: `${CUSTOM_FIELD_PREFIX}${startCase(field.name)}`,
          dataSet: field.selectOptions.map((opt) => ({
            value: opt,
            text: opt,
            key: opt,
          })),
          dataType: DataType.JOB,
          type: (field.type as unknown) as DataFormType,
          optional: !field.isRequired ? true : undefined,
        })),
      },
    ];

    for (const stage of getModalStageSections()) {
      sections.push(stage);
    }

    return sections;
  };

  return (
    <DataModal
      showDataForm={props.showDataForm}
      onClose={() => {
        resetDataForm();
        props.resetDataType();
      }}
      searchAction={props.searchAction}
      crudAction={async (
        action: CRUDType,
        dataType: DataType,
        data: any,
        completeAction: boolean = true,
        clearFilter: boolean = false,
        addAction: any,
      ) => {
        if (action === CRUDType.DELETE) {
          await props.actionHelper.handleAction(
            dataType,
            null,
            action,
            data._id,
            undefined,
            undefined,
            props.callback || null,
          );
        } else if (action === CRUDType.CANCEL) {
          await props.actionHelper.handleAction(
            dataType,
            null,
            action,
            data._id,
            undefined,
            undefined,
            addAction || props.callback || null,
          );
        } else {
          // Process Custom Fields
          const processed = Object.entries(data).reduce((acc: Job, [k, v]) => {
            if (!k.includes(CUSTOM_FIELD_PREFIX)) {
              return {...acc, [k]: v};
            }

            const cleanKey = k.replace(CUSTOM_FIELD_PREFIX, '');

            return {
              ...acc,
              customFields: [
                ...(acc.customFields || []),
                {
                  field: cleanKey,
                  value: v,
                },
              ],
            };
          }, {} as Job);

          const result = await props.crudAction(
            processed._id?.length ? CRUDType.UPDATE : action || CRUDType.UPDATE,
            dataType,
            addEvents(processed),
            completeAction,
            clearFilter,
            props.callback || null,
          );
          props.setSelected(result?.data);
        }
        props.resetDataType();
      }}
      title={props.selected.jobId || props.selected._id}
      data={{
        selected: Object.entries(props.selected).reduce((acc: Job, [k, v]) => {
          if (k !== 'customFields') {
            if (k === 'company' && !v) {
              return {...acc, [k]: props.loggedInCompany._id};
            }
            return {...acc, [k]: v};
          }
          const cf = (v as {field: string; value: unknown}[]).reduce(
            (subAcc, cf) => {
              return {
                ...subAcc,
                [`${CUSTOM_FIELD_PREFIX}${cf.field}`]: cf.value,
              };
            },
            {},
          );

          return {
            ...acc,
            ...cf,
          };
        }, {} as Job),
        loggedInCompany: props.loggedInCompany,
        loggedInUser: props.loggedInUser,
        customer: !props.selected.jobId
          ? null
          : props.customers.find((c) => {
              return (
                c._id ===
                (formData?.selected?.customer || props.selected.customer)
              );
            }),
      }}
      setFormData={(data) => {
        const updated = {
          ...data,
          customer: props.customers.find((c) => {
            return (
              c._id ===
              (formData?.selected?.customer || props.selected.customer)
            );
          }),
        };
        setFormData(updated);
      }}
      dataType={DataType.JOB}
      sections={createJobFormSections()}
    />
  );
};

export default JobModal;
